<template>
  <form ref="authForm" class="login-form" @submit.prevent="onSubmitForm">
    <h1 class="auth-title">Sign in to save your resume</h1>

    <div class="auth-social">
      <SocialAuthButtonsModal buttonText="Sign in with Google" />
    </div>

    <div class="auth-bar">
      <span class="auth-bar__label">OR</span>
    </div>

    <AppField
      type="email"
      label="Enter your e-mail"
      required
      :isInvalid="$v.email.$error"
      :value="email"
      @input="setField($event, 'email')"
      class="modal-input-field"
    >
      <template v-slot:error>
        <p v-if="!$v.email.required">This field is required</p>
        <p v-if="!$v.email.email">Incorrect email format</p>
      </template>
    </AppField>

    <AppField
      type="password"
      label="Password"
      required
      :isInvalid="$v.password.$error"
      @input="setField($event, 'password')"
      class="modal-input-field"
    >
      <template v-slot:error>
        <p v-if="!$v.password.required">This field is required</p>
        <p v-if="!$v.password.minLength">
          Password must have at least
          {{ $v.password.$params.minLength.min }} symbols.
        </p>
      </template>
    </AppField>

    <div class="btn-wrap">
      <AppButton
        title="Log In"
        class="form-btn auth-register-btn"
        className="primary"
        type="submit"
        :pending="pending"
      />
    </div>

    <div class="btn-wrap">
      <AppButton
        title="Skip For Now"
        class="form-btn auth-register-btn"
        className="secondary"
        type="button"
        @click.prevent="skipForNow()"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { email, minLength, required } from "vuelidate/lib/validators";

import {
  ROUT_PATH,
  SUBSCRIBE_STEPS_NAMES,
  USER_ACCOUNT_NAMES,
} from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { authMixin } from "@/mixins/AuthMixin";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";
import CoverLetter from "@/helpers/cover-letter-hash";
import ResumeHash from "@/helpers/resume-hash";
import SocialAuthButtonsModal from "@/components/auth/SocialAuthButtonsModal";

export default {
  name: "Login",
  mixins: [authMixin, mixpanelMixin],
  components: {
    SocialAuthButtonsModal,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
    }),
  },

  props: {
    p_email: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      email: "",
      password: "",
      doc_hash: "",
      doc_type: "",
      ROUT_PATH,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },

  async created() {
    let resumeHash = ResumeHash.get();
    let coverLetterHash = CoverLetter.get();

    this.email = this.p_email;

    if (resumeHash != "") {
      this.doc_hash = resumeHash;
      this.doc_type = "resume";
    } else if (coverLetterHash != "") {
      this.doc_hash = coverLetterHash;
      this.doc_type = "letter";
    }
  },

  methods: {
    async skipForNow() {
      await this.$router.push(ROUT_PATH.CREATING_RESUME_PATH.STEP_2);
    },

    async onSubmitForm() {
      const payload = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.sendData(payload, "auth/login");
      } catch (e) {
        return;
      }

      FullStory.setUserVars({
        email: this.email,
      });

      if (this.doc_hash != "") {
        CompletedTemplate.set({ hash: this.doc_hash, type: this.doc_type });

        if (this.doc_type == "resume") {
          await this.$store.dispatch("resumeCreation/bindResumeToUser");
          ResumeHash.set(this.doc_hash);
        }
        if (this.doc_type == "letter") {
          await this.$store.dispatch("coverLetterCreation/bindLetterToUser");
          CoverLetter.set(this.doc_hash);
        }
      }

      // this.mixpanelTrack("V2-LOGIN-POPUP", {
      //   email: this.email,
      // });

      await this.$router.push(ROUT_PATH.CREATING_RESUME_PATH.STEP_2);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-social {
  margin-top: 20px;
  margin-bottom: 35px;
}
.auth-register-btn {
  width: 100%;
  font-size: 18px;
}
.btn-wrap {
  margin-bottom: 10px;
}
.auth-bar {
  border-top: 1px solid #c1c0c0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  color: #c1c0c0;

  &__label {
    padding-left: 10px;
    padding-right: 10px;
    width: 40px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: -15px;
  }
}

@media screen and (max-height: 768px) {
  .auth-title {
    font-size: 1rem;
  }
  .auth-social {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .auth-bar {
    margin-bottom: 30px;
  }
  .auth-register-btn {
    height: 45px;
  }
}

@media screen and (max-height: 568px) {
  .auth-title {
    font-size: 1rem;
  }
  .auth-social {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .auth-bar {
    margin-bottom: 15px;
  }
  .auth-register-btn {
    height: 40px;
    font-size: 15px;
  }
}
</style>
<style lang="scss">
@media screen and (max-height: 568px) {
  .modal-input-field input {
    height: 40px !important;
  }
}
</style>
