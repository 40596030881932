<template>
  <div>
    <RegistrationForm v-if="showCreateAccount" :p_email="p_email" />
    <LoginForm v-if="!showCreateAccount" :p_email="p_email" />

    <div class="btn-wrap">
      <p v-if="!showCreateAccount">
        <router-link class="btn-wrap__link" :to="ROUT_PATH.AUTH.RESET_PASSWORD"
          >Forgot Password?
        </router-link>
      </p>
      <p>
        <span
          class="btn-wrap__link"
          v-html="actionButtonLabel"
          @click.prevent="actionButton()"
          >Already have an account?</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ROUT_PATH,
  SUBSCRIBE_STEPS_NAMES,
  USER_ACCOUNT_NAMES,
} from "@/helpers/rout-constants";
import { authMixin } from "@/mixins/AuthMixin";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";
import RegistrationForm from "@/components/account/RegistrationForm";
import LoginForm from "@/components/account/LoginForm";

export default {
  name: "CreateAccountModal",
  mixins: [authMixin, mixpanelMixin],
  components: { RegistrationForm, LoginForm },

  props: {
    p_email: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showCreateAccount: true,
      actionButtonLabel: "Already have an account?",
      ROUT_PATH,
    };
  },

  async created() {
    this.mixpanelTrack("V2-REGISTRATION-POPUP");
  },

  methods: {
    async actionButton() {
      if (this.showCreateAccount) {
        this.showCreateAccount = false;
        this.actionButtonLabel = "Not Registered Yet?";
      } else {
        this.showCreateAccount = true;
        this.actionButtonLabel = "Already have an account?";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrap {
  text-align: center;
  color: #4874e6;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 13px;
  color: #4874e6;

  &__link {
    cursor: pointer;
    color: #4874e6;
    font-size: 13px;
  }
}
</style>
