<template>
  <div class="creation-step">
    <div class="step-heading">
      <h1 class="step-heading__title">Start with your header</h1>
      <p class="step-heading__text">
        Provide employers your complete name and contact details.
      </p>
    </div>

    <FirstStepForm
      v-if="resume"
      :userData="resume"
      isAvailableUserPhoto
      @next="submitStep"
      @input="updatePreview"
    />

    <AppModal
      :isOpenModal="isOpenModal"
      width="460px"
      @close="closeModal"
      hideClose="true"
    >
      <CreateAccountModal :p_email="email" />
    </AppModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";
import { resumeCreationMixin } from "@/mixins/ResumeCreationStepsMixin";
import FirstStepForm from "@/components/creation/FirstStepForm";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import ResumeHash from "@/helpers/resume-hash";
import { COMPONENT_NAMES } from "@/helpers/components-names";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CreateAccountModal from "@/components/account/CreateAccountModal";
import CookieHelper from "@/helpers/cookie-helper";

export default {
  name: COMPONENT_NAMES.RESUME_CREATION.BASE_INFO,
  mixins: [resumeCreationMixin, mixpanelMixin],
  components: { FirstStepForm, CreateAccountModal },

  data() {
    return {
      nextStepName: CREATING_RESUME_NAMES.STEP_2,
      isOpenModal: false,
      skip_reg: "",
      email: "",
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
    }),
  },

  async created() {
    this.mixpanelTrack("V2-BASEINFO");
    this.skip_reg = CookieHelper.getFlag("skip_reg", "");

    await this.getInitialResume();
  },

  methods: {
    async updatePreview(data) {
      try {
        await this.$store.dispatch("resumeCreation/updateResume", data);

        await setIntermediateResume();
      } catch (e) {
        // console.error(e);
      }
    },

    async submitStep(resumeData) {
      await this.$store.dispatch("loader/pending");

      try {
        const updateResumeData = {
          hash:
            this.$store.getters["resumeCreation/getResumeHash"] ||
            ResumeHash.get(),
          ...resumeData,
        };

        await this.$store.dispatch(
          "resumeCreation/forceUpdateResume",
          updateResumeData
        );

        if (this.skip_reg == "1") {
          if (this.isLoggedIn) {
            await this.goNextStep();
          } else {
            this.email = resumeData.email;
            this.isOpenModal = true;
          }
        } else {
          await this.goNextStep();
        }
      } catch (e) {
        // console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .modal {
  @media (max-width: map_get($grid-breakpoints, lg)) {
    padding: 25px;
  }
}
</style>
