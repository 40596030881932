<template>
  <div class="social-auth">
    <button
      class="social-btn social-btn--google"
      @click.prevent="authProvider('google')"
    >
      <AppIcon componentName="GoogleIcon" />
      <span v-html="buttonText">Sign up with Google</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUT_PATH, USER_ACCOUNT_NAMES } from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { authMixin } from "@/mixins/AuthMixin";
import CoverLetter from "@/helpers/cover-letter-hash";
import ResumeHash from "@/helpers/resume-hash";

export default {
  mixins: [authMixin],

  props: {
    buttonText: {
      type: String,
      default: "Sign up with Google",
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
    }),
  },

  data() {
    return {
      ROUT_PATH,
      doc_hash: "",
      doc_type: "",
    };
  },

  async created() {
    let resumeHash = ResumeHash.get();
    let coverLetterHash = CoverLetter.get();

    if (resumeHash != "") {
      this.doc_hash = resumeHash;
      this.doc_type = "resume";
    } else if (coverLetterHash != "") {
      this.doc_hash = coverLetterHash;
      this.doc_type = "letter";
    }
  },

  methods: {
    authProvider(provider) {
      const self = this;

      this.$auth
        .authenticate(provider)
        .then(async (response) => {
          await self.$store.dispatch("loader/pending");

          const socialAuthData = {
            provider,
            ...response,
          };

          await self.sendSocialData(socialAuthData);

          await self.$store.dispatch("loader/done");
        })
        .catch((err) => {
          self.$store.dispatch("loader/done");
          console.error(err);
        });
    },

    async sendSocialData(payloadFields) {
      try {
        this.pending = true;
        await this.$store.dispatch("auth/loginSocial", payloadFields);

        if (this.doc_hash != "") {
          CompletedTemplate.set({ hash: this.doc_hash, type: this.doc_type });

          if (this.doc_type == "resume") {
            await this.$store.dispatch("resumeCreation/bindResumeToUser");
            ResumeHash.set(this.doc_hash);
          }
          if (this.doc_type == "letter") {
            await this.$store.dispatch("coverLetterCreation/bindLetterToUser");
            CoverLetter.set(this.doc_hash);
          }
        }

        // this.mixpanelTrack("V2-REGISTERED-POPUP", {
        //   email: this.email,
        // });

        await this.$router.push(ROUT_PATH.CREATING_RESUME_PATH.STEP_2);
      } catch (e) {
        console.error(e);
      }

      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: map_get($grid-breakpoints, md)) {
    flex-wrap: nowrap;
  }

  ::v-deep .social-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      flex: 1;
      width: auto;
      margin-bottom: 0;
      margin-right: 16px;
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }

    &--facebook {
      background-color: #3f588f;
      color: #fff;
    }

    &--linkedin {
      background-color: #4077ad;
      color: #fff;
    }

    &--google {
      border: 1px solid #d2d2d2;
    }
  }
}

@media screen and (max-height: 768px) {
  .social-btn {
    height: 45px !important;
  }
}
@media screen and (max-height: 568px) {
  .social-btn {
    height: 40px !important;
    font-size: 13px !important;
  }
}
</style>
